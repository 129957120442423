/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Layout from "./layout";
import { NavbarTopCenteredElement } from "./elements/navbar/navbar.top.centered.element";
import { COLOR } from "./../components/color.classnames";
import { useStaticQuery, graphql } from "gatsby";
import BlockFooterMission from "./blocks/footer/block.footer.mission";

const LayoutGeneral = ({
  pageContext,
  children,
  path,
  navigation,
  footer,
  social,
}) => {
  const dataQuery = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logos/echonovum-landscape.svg" }) {
        id
        publicURL
        absolutePath
        name
        size
        uid
        sourceInstanceName
        relativePath
        relativeDirectory
        prettySize
        modifiedTime
        extension
        dir
        base
        childImageSharp {
          id
          fluid(maxWidth: 1080) {
            base64
            aspectRatio
            src
            srcSet
            sizes
            srcWebp
            srcSetWebp
          }
          fixed(width: 1080) {
            base64
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  `);

  const { language } = pageContext || { language: "de" };

  const navbarData = {
    logo: "logo",
    items: navigation?.data || [],
    cta: {
      primary: {
        title: "Jetzt Rückruf anfordern",
        link: "/contact/rueckruf",
      },
      secondary: {
        title: "Login",
        link: "https://hub.echonovum.com",
      },
    },
    rescue: language?.toLowerCase() === "de" ? "/" : "/en",
  };

  const assets = {
    logo: {
      key: "logo",
      src: dataQuery.logo,
      alt: "echonovum",
    },
  };

  const footerData = {
    color: COLOR.pink,
    isDark: false,
    logo: "logo",
    mission:
      language?.toLowerCase() === "de"
        ? "Treffen Sie täglich bessere Entscheidungen."
        : "Take better decisions every day.",
    copyright: `© ${new Date().getFullYear()} echonovum AG, Schaffhauserstrasse 18, 8006 Zürich | Telefon +41 44 244 19 19`,
    social: social?.data,
    navigation: footer?.data,
  };

  return (
    <Fragment>
      <div className="mx-auto max-w-screen-xl">
        <NavbarTopCenteredElement
          data={navbarData}
          color={COLOR.pink}
          assets={assets}
        />
      </div>

      <Layout pageContext={pageContext} path={path}>
        {children}
      </Layout>
      <div className="mx-auto max-w-screen-xl">
        <BlockFooterMission data={footerData} assets={assets} />
      </div>
    </Fragment>
  );
};

LayoutGeneral.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default LayoutGeneral;
