import React, { Component } from "react";
import { graphql } from "gatsby";

import LayoutGeneral from "../components/layout.general";
import { CourseList } from "../components/elements/courses/course-list.component";
import { BreadcrumbsElement } from "../components/elements/navbar/breadcrumbs.element";
import { BlogListMiniHero } from "./../components/elements/articles/article-list-mini-hero.component";
import { IconElement } from "../components/elements/icon.element";
import { SubHeadingList } from "../components/elements/heading/sub-heading-list.component";

export default class CourseIndex extends Component {
  render() {
    const { mdx: pageData } = this.props.data || {};
    const { frontmatter } = pageData || {};
    const modules = this.props.data.allMdx.edges;
    const context = this.props.pageContext;
    const { language } = context || { language: "de" };

    const navigation =
      language?.toLowerCase() === "de"
        ? this.props.data?.navigationDe
        : this.props.data?.navigationEn;

    const footer =
      language?.toLowerCase() === "de"
        ? this.props.data?.footerDe
        : this.props.data?.footerEn;

    const seo = {
      title: frontmatter?.title,
      description: frontmatter?.description,
    };

    const enrichedPageContext = {
      seo,
      ...context,
    };

    return (
      <LayoutGeneral
        pageContext={enrichedPageContext}
        navigation={navigation}
        footer={footer}
        path={this.props?.location?.pathname}
        seo={seo}
      >
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3"></div>
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="md:flex justify-between">
              <div className="text-left">
                {Array.isArray(frontmatter?.breadcrumbs) && (
                  <div className="mb-4">
                    <BreadcrumbsElement items={frontmatter.breadcrumbs} />
                  </div>
                )}

                {frontmatter?.headline && (
                  <h1 className="text-3xl  max-w-3xl  tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                    {frontmatter.headline}
                  </h1>
                )}

                {frontmatter?.description && (
                  <p className="mt-3 max-w-2xl text-xl text-gray-700 sm:mt-4">
                    {frontmatter.description}
                  </p>
                )}

                {Array.isArray(frontmatter?.descriptionFeatures) && (
                  <div className="my-4">
                    <SubHeadingList
                      features={frontmatter.descriptionFeatures}
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center">
                <div className="my-4 md:my-0 w-96 max-w-full mx-auto md:mx-0 md:ml-6">
                  <div className="relative w-full rounded-md overflow-hidden shadow-xl">
                    {Array.isArray(modules) &&
                      modules.length &&
                      modules[0].node?.fields?.slug && (
                        <a
                          href={modules[0].node.fields.slug}
                          className="absolute inset-0 flex justify-center items-center transition duration-200 bg-opacity-0 hover:bg-opacity-20 bg-transparent hover:bg-gray-50 z-20"
                          data-tracking-action-label="CTA"
                          data-tracking-action-value={`Module Preview Image | ${modules[0].node.fields.slug}`}
                          data-tracking-action-position="Main Module Preview CTA"
                        >
                          <span className="w-16 h-16 rounded-full text-xl flex justify-center items-center bg-white">
                            <IconElement icon="play" />
                          </span>
                        </a>
                      )}
                    <BlogListMiniHero
                      leadImage={frontmatter?.leadImage}
                      hero={frontmatter?.hero}
                    />
                  </div>

                  <div className="my-6 flex items-center justify-center">
                    {Array.isArray(modules) &&
                      modules.length &&
                      modules[0].node?.fields?.slug && (
                        <a
                          href={modules[0].node.fields.slug}
                          type="button"
                          className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                          data-tracking-action-label="CTA"
                          data-tracking-action-value={`Jetzt loslegen | ${modules[0].node.fields.slug}`}
                          data-tracking-action-position="Course Index Start CTA"
                        >
                          <span className="mr-4 text-sm">
                            <IconElement icon="play" />
                          </span>
                          <strong className="block font-medium tracking-wide">
                            Jetzt loslegen
                          </strong>
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 max-w-full mx-auto lg:max-w-none lg:flex">
              <div className="lg:max-w-auto flex-grow">
                <CourseList modules={modules} />
              </div>
              <div className="lg:flex-shrink-0 lg:w-96 lg:ml-6">
                {Array.isArray(frontmatter?.featureSections) &&
                  frontmatter.featureSections.map(section => (
                    <div className="py-6 mb-8 px-6 border" key={section.title}>
                      {section.title && (
                        <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                          {section.title}
                        </h3>
                      )}
                      {section.description && (
                        <p className="mt-3 max-w-2xl text-sm text-gray-600 sm:mt-4">
                          {section.description}
                        </p>
                      )}
                      <ul className="mt-6 space-y-4">
                        {section.features &&
                          section.features.map(feature => (
                            <li key={feature} className="flex space-x-3">
                              <span className="flex-shrink-0 h-5 w-5 text-green-500">
                                <IconElement icon="check" />
                              </span>
                              <span className="text-sm text-gray-600">
                                {feature}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </LayoutGeneral>
    );
  }
}

export const courseIndexQuery = graphql`
  query courseIndexQuery(
    $language: String!
    $courseKey: String!
    $slug: String!
  ) {
    navigationDe: fragmentsYaml(key: { eq: "navigation-reduced" }) {
      key
      id
      data
    }
    navigationEn: fragmentsYaml(key: { eq: "navigation-reduced-en" }) {
      key
      id
      data
    }
    footerDe: fragmentsYaml(key: { eq: "footer-navigation" }) {
      key
      id
      data
    }
    footerEn: fragmentsYaml(key: { eq: "footer-navigation-en" }) {
      key
      id
      data
    }
    social: fragmentsYaml(key: { eq: "social-profiles" }) {
      key
      id
      data
    }
    mdx: mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date
        slug
        title
        headline
        description
        topic
        breadcrumbs {
          title
          url
        }
        descriptionFeatures {
          icon
          text
        }
        featureSections {
          title
          description
          features
        }
        courseKey
        duration
        modules {
          key
          public
          title
        }
        lead_image {
          publicURL
          childImageSharp {
            id
            fluid(maxWidth: 1080) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              srcWebp
              srcSetWebp
            }
            fixed(width: 1080) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        hero {
          theme
          background_color
          accent_color
          foreground_image {
            publicURL
            childImageSharp {
              id
              fluid(maxWidth: 1080) {
                base64
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
              fixed(width: 1080) {
                base64
                aspectRatio
                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
    allMdx: allMdx(
      filter: {
        frontmatter: {
          type: { eq: "course-module" }
          courseKey: { eq: $courseKey }
          language: { eq: $language }
          draft: { ne: true }
        }
      }
      sort: {
        fields: [frontmatter___order, frontmatter___date]
        order: [ASC, DESC]
      }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          tableOfContents
          frontmatter {
            headline
            description
            topic
            date(formatString: "DD.MM.YYYY")
            courseKey
            duration
            public
            lead_image {
              publicURL
              childImageSharp {
                id
                fluid(maxWidth: 1080) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
                fixed(width: 1080) {
                  base64
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
            hero {
              theme
              background_color
              accent_color
              foreground_image {
                publicURL
                childImageSharp {
                  id
                  fluid(maxWidth: 1080) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    srcWebp
                    srcSetWebp
                  }
                  fixed(width: 1080) {
                    base64
                    aspectRatio
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
