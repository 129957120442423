import React, { Component } from "react";
import { CourseModuleToc } from "./course-module-toc.component";

export class CourseList extends Component {
  render() {
    const { modules } = this.props;

    if (!(Array.isArray(modules) && modules.length)) {
      return null;
    }

    return (
      <div className="divide-y divide-gray-200 bg-white">
        {modules.map(module => (
          <CourseModuleToc key={module.node.fields.slug} {...module} />
        ))}
      </div>
    );
  }
}
