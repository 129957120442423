// import palette from "echo-colors/colors";
// import camelCase from "camelcase";

// function buildUsages(key, usages, weights) {
//   return usages.reduce((a, c) => {
//     a[camelCase(c)] = weights.reduce((acc, weight) => {
//       acc[camelCase(weight.toString())] = [c, key, weight].join("-");
//       return acc;
//     }, {});
//     return a;
//   }, {});
// }

// console.log(palette);

// const paletteList = Object.entries(palette);

// const usages = ["text", "bg", "border", "shadow-outline"];

// for (const [key, colors] of paletteList) {
//   const colorWeights = Object.keys(colors);

//   COLOR[key] = buildUsages(key, usages, colorWeights);

//   console.log(JSON.stringify(COLOR, null, 4));
// }

export const COLOR = {
  pink: {
    text: {
      50: "text-pink-50",
      100: "text-pink-100",
      200: "text-pink-200",
      300: "text-pink-300",
      400: "text-pink-400",
      500: "text-pink-500",
      600: "text-pink-600",
      700: "text-pink-700",
      800: "text-pink-800",
      900: "text-pink-900",
    },
    bg: {
      50: "bg-pink-50",
      100: "bg-pink-100",
      200: "bg-pink-200",
      300: "bg-pink-300",
      400: "bg-pink-400",
      500: "bg-pink-500",
      600: "bg-pink-600",
      700: "bg-pink-700",
      800: "bg-pink-800",
      900: "bg-pink-900",
    },
    border: {
      50: "border-pink-50",
      100: "border-pink-100",
      200: "border-pink-200",
      300: "border-pink-300",
      400: "border-pink-400",
      500: "border-pink-500",
      600: "border-pink-600",
      700: "border-pink-700",
      800: "border-pink-800",
      900: "border-pink-900",
    },
    shadowOutline: {
      50: "shadow-outline-pink-50",
      100: "shadow-outline-pink-100",
      200: "shadow-outline-pink-200",
      300: "shadow-outline-pink-300",
      400: "shadow-outline-pink-400",
      500: "shadow-outline-pink-500",
      600: "shadow-outline-pink-600",
      700: "shadow-outline-pink-700",
      800: "shadow-outline-pink-800",
      900: "shadow-outline-pink-900",
    },
  },
  purple: {
    text: {
      50: "text-purple-50",
      100: "text-purple-100",
      200: "text-purple-200",
      300: "text-purple-300",
      400: "text-purple-400",
      500: "text-purple-500",
      600: "text-purple-600",
      700: "text-purple-700",
      800: "text-purple-800",
      900: "text-purple-900",
    },
    bg: {
      50: "bg-purple-50",
      100: "bg-purple-100",
      200: "bg-purple-200",
      300: "bg-purple-300",
      400: "bg-purple-400",
      500: "bg-purple-500",
      600: "bg-purple-600",
      700: "bg-purple-700",
      800: "bg-purple-800",
      900: "bg-purple-900",
    },
    border: {
      50: "border-purple-50",
      100: "border-purple-100",
      200: "border-purple-200",
      300: "border-purple-300",
      400: "border-purple-400",
      500: "border-purple-500",
      600: "border-purple-600",
      700: "border-purple-700",
      800: "border-purple-800",
      900: "border-purple-900",
    },
    shadowOutline: {
      50: "shadow-outline-purple-50",
      100: "shadow-outline-purple-100",
      200: "shadow-outline-purple-200",
      300: "shadow-outline-purple-300",
      400: "shadow-outline-purple-400",
      500: "shadow-outline-purple-500",
      600: "shadow-outline-purple-600",
      700: "shadow-outline-purple-700",
      800: "shadow-outline-purple-800",
      900: "shadow-outline-purple-900",
    },
  },
  blue: {
    text: {
      50: "text-blue-50",
      100: "text-blue-100",
      200: "text-blue-200",
      300: "text-blue-300",
      400: "text-blue-400",
      500: "text-blue-500",
      600: "text-blue-600",
      700: "text-blue-700",
      800: "text-blue-800",
      900: "text-blue-900",
    },
    bg: {
      50: "bg-blue-50",
      100: "bg-blue-100",
      200: "bg-blue-200",
      300: "bg-blue-300",
      400: "bg-blue-400",
      500: "bg-blue-500",
      600: "bg-blue-600",
      700: "bg-blue-700",
      800: "bg-blue-800",
      900: "bg-blue-900",
    },
    border: {
      50: "border-blue-50",
      100: "border-blue-100",
      200: "border-blue-200",
      300: "border-blue-300",
      400: "border-blue-400",
      500: "border-blue-500",
      600: "border-blue-600",
      700: "border-blue-700",
      800: "border-blue-800",
      900: "border-blue-900",
    },
    shadowOutline: {
      50: "shadow-outline-blue-50",
      100: "shadow-outline-blue-100",
      200: "shadow-outline-blue-200",
      300: "shadow-outline-blue-300",
      400: "shadow-outline-blue-400",
      500: "shadow-outline-blue-500",
      600: "shadow-outline-blue-600",
      700: "shadow-outline-blue-700",
      800: "shadow-outline-blue-800",
      900: "shadow-outline-blue-900",
    },
  },
  red: {
    text: {
      50: "text-red-50",
      100: "text-red-100",
      200: "text-red-200",
      300: "text-red-300",
      400: "text-red-400",
      500: "text-red-500",
      600: "text-red-600",
      700: "text-red-700",
      800: "text-red-800",
      900: "text-red-900",
    },
    bg: {
      50: "bg-red-50",
      100: "bg-red-100",
      200: "bg-red-200",
      300: "bg-red-300",
      400: "bg-red-400",
      500: "bg-red-500",
      600: "bg-red-600",
      700: "bg-red-700",
      800: "bg-red-800",
      900: "bg-red-900",
    },
    border: {
      50: "border-red-50",
      100: "border-red-100",
      200: "border-red-200",
      300: "border-red-300",
      400: "border-red-400",
      500: "border-red-500",
      600: "border-red-600",
      700: "border-red-700",
      800: "border-red-800",
      900: "border-red-900",
    },
    shadowOutline: {
      50: "shadow-outline-red-50",
      100: "shadow-outline-red-100",
      200: "shadow-outline-red-200",
      300: "shadow-outline-red-300",
      400: "shadow-outline-red-400",
      500: "shadow-outline-red-500",
      600: "shadow-outline-red-600",
      700: "shadow-outline-red-700",
      800: "shadow-outline-red-800",
      900: "shadow-outline-red-900",
    },
  },
  yellow: {
    text: {
      50: "text-yellow-50",
      100: "text-yellow-100",
      200: "text-yellow-200",
      300: "text-yellow-300",
      400: "text-yellow-400",
      500: "text-yellow-500",
      600: "text-yellow-600",
      700: "text-yellow-700",
      800: "text-yellow-800",
      900: "text-yellow-900",
    },
    bg: {
      50: "bg-yellow-50",
      100: "bg-yellow-100",
      200: "bg-yellow-200",
      300: "bg-yellow-300",
      400: "bg-yellow-400",
      500: "bg-yellow-500",
      600: "bg-yellow-600",
      700: "bg-yellow-700",
      800: "bg-yellow-800",
      900: "bg-yellow-900",
    },
    border: {
      50: "border-yellow-50",
      100: "border-yellow-100",
      200: "border-yellow-200",
      300: "border-yellow-300",
      400: "border-yellow-400",
      500: "border-yellow-500",
      600: "border-yellow-600",
      700: "border-yellow-700",
      800: "border-yellow-800",
      900: "border-yellow-900",
    },
    shadowOutline: {
      50: "shadow-outline-yellow-50",
      100: "shadow-outline-yellow-100",
      200: "shadow-outline-yellow-200",
      300: "shadow-outline-yellow-300",
      400: "shadow-outline-yellow-400",
      500: "shadow-outline-yellow-500",
      600: "shadow-outline-yellow-600",
      700: "shadow-outline-yellow-700",
      800: "shadow-outline-yellow-800",
      900: "shadow-outline-yellow-900",
    },
  },
  green: {
    text: {
      50: "text-green-50",
      100: "text-green-100",
      200: "text-green-200",
      300: "text-green-300",
      400: "text-green-400",
      500: "text-green-500",
      600: "text-green-600",
      700: "text-green-700",
      800: "text-green-800",
      900: "text-green-900",
    },
    bg: {
      50: "bg-green-50",
      100: "bg-green-100",
      200: "bg-green-200",
      300: "bg-green-300",
      400: "bg-green-400",
      500: "bg-green-500",
      600: "bg-green-600",
      700: "bg-green-700",
      800: "bg-green-800",
      900: "bg-green-900",
    },
    border: {
      50: "border-green-50",
      100: "border-green-100",
      200: "border-green-200",
      300: "border-green-300",
      400: "border-green-400",
      500: "border-green-500",
      600: "border-green-600",
      700: "border-green-700",
      800: "border-green-800",
      900: "border-green-900",
    },
    shadowOutline: {
      50: "shadow-outline-green-50",
      100: "shadow-outline-green-100",
      200: "shadow-outline-green-200",
      300: "shadow-outline-green-300",
      400: "shadow-outline-green-400",
      500: "shadow-outline-green-500",
      600: "shadow-outline-green-600",
      700: "shadow-outline-green-700",
      800: "shadow-outline-green-800",
      900: "shadow-outline-green-900",
    },
  },
  gray: {
    text: {
      50: "text-gray-50",
      100: "text-gray-100",
      200: "text-gray-200",
      300: "text-gray-300",
      400: "text-gray-400",
      500: "text-gray-500",
      600: "text-gray-600",
      700: "text-gray-700",
      800: "text-gray-800",
      900: "text-gray-900",
    },
    bg: {
      50: "bg-gray-50",
      100: "bg-gray-100",
      200: "bg-gray-200",
      300: "bg-gray-300",
      400: "bg-gray-400",
      500: "bg-gray-500",
      600: "bg-gray-600",
      700: "bg-gray-700",
      800: "bg-gray-800",
      900: "bg-gray-900",
    },
    border: {
      50: "border-gray-50",
      100: "border-gray-100",
      200: "border-gray-200",
      300: "border-gray-300",
      400: "border-gray-400",
      500: "border-gray-500",
      600: "border-gray-600",
      700: "border-gray-700",
      800: "border-gray-800",
      900: "border-gray-900",
    },
    shadowOutline: {
      50: "shadow-outline-gray-50",
      100: "shadow-outline-gray-100",
      200: "shadow-outline-gray-200",
      300: "shadow-outline-gray-300",
      400: "shadow-outline-gray-400",
      500: "shadow-outline-gray-500",
      600: "shadow-outline-gray-600",
      700: "shadow-outline-gray-700",
      800: "shadow-outline-gray-800",
      900: "shadow-outline-gray-900",
    },
  },
  cyan: {
    text: {
      50: "text-cyan-50",
      100: "text-cyan-100",
      200: "text-cyan-200",
      300: "text-cyan-300",
      400: "text-cyan-400",
      500: "text-cyan-500",
      600: "text-cyan-600",
      700: "text-cyan-700",
      800: "text-cyan-800",
      900: "text-cyan-900",
    },
    bg: {
      50: "bg-cyan-50",
      100: "bg-cyan-100",
      200: "bg-cyan-200",
      300: "bg-cyan-300",
      400: "bg-cyan-400",
      500: "bg-cyan-500",
      600: "bg-cyan-600",
      700: "bg-cyan-700",
      800: "bg-cyan-800",
      900: "bg-cyan-900",
    },
    border: {
      50: "border-cyan-50",
      100: "border-cyan-100",
      200: "border-cyan-200",
      300: "border-cyan-300",
      400: "border-cyan-400",
      500: "border-cyan-500",
      600: "border-cyan-600",
      700: "border-cyan-700",
      800: "border-cyan-800",
      900: "border-cyan-900",
    },
    shadowOutline: {
      50: "shadow-outline-cyan-50",
      100: "shadow-outline-cyan-100",
      200: "shadow-outline-cyan-200",
      300: "shadow-outline-cyan-300",
      400: "shadow-outline-cyan-400",
      500: "shadow-outline-cyan-500",
      600: "shadow-outline-cyan-600",
      700: "shadow-outline-cyan-700",
      800: "shadow-outline-cyan-800",
      900: "shadow-outline-cyan-900",
    },
  },
};
