import React, { Component } from "react";
import { ImageElement } from "../image.element";

const HERO_THEME_BG = {
  neutral: "#f2f2f2",
  magical: "#b86ea7",
  aggressive: "#2196f3",
  frozen: "#36c9e3",
};

export class BlogListMiniHero extends Component {
  heroBgColor({ theme, background_color }) {
    if (background_color) {
      return background_color;
    }

    return HERO_THEME_BG[theme] || "#fcfcfc";
  }

  render() {
    const { leadImage, hero } = this.props;
    return (
      <div
        className="flex-shrink-0"
        style={{ backgroundColor: this.heroBgColor(hero) }}
      >
        {hero?.foreground_image && (
          <ImageElement
            src={hero?.foreground_image}
            isFluid={true}
            className="h-48 w-full"
            objectFit="cover"
          />
        )}
        {!hero?.foreground_image && leadImage && (
          <ImageElement
            src={leadImage}
            isFluid={true}
            className="h-48 w-full"
            objectFit="cover"
          />
        )}
      </div>
    );
  }
}
