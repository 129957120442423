import React, { Fragment } from "react";
import { IconElement } from "../icon.element";

function CourseModuleTocItem(props) {
  return props?.embedded ? (
    <CourseModuleTocItemEmbedded {...props} />
  ) : (
    <CourseModuleTocItemRegular {...props} />
  );
}

function CourseModuleTocItemEmbedded({ url, title, level }) {
  return (
    <a
      href={url}
      className="flex items-start group"
      aria-current="step"
      data-tracking-action-label="NAVIGATION"
      data-tracking-action-value={`${title} | ${url}`}
      data-tracking-action-position="Article Embedded ToC"
    >
      <span
        className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
        aria-hidden="true"
      >
        <span className="absolute h-4 w-4 rounded-full bg-pink-200 opacity-0 group-hover:opacity-50 transition duration-200" />
        <span className="relative block w-2 h-2 bg-gray-600 group-hover:bg-pink-600 rounded-full transform scale-50 group-hover:scale-100 transition-transform duration-200" />
      </span>
      <span
        className={`ml-3 text-base ${
          level === 1 ? "font-medium" : "font-normal"
        } text-gray-700 group-hover:text-pink-600 `}
      >
        {title}
      </span>
    </a>
  );
}

function CourseModuleTocItemRegular({
  url,
  title,
  description,
  duration,
  level,
  locked = true,
}) {
  const levelTextStyles = [
    "text-sm font-medium text-gray-50",
    "text-sm font-medium text-gray-800",
    "text-sm font-regular text-gray-700",
  ];
  const levelBlockStyles = [
    "relative text-gray-50 bg-gray-600 py-5 px-4 hover:bg-gray-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-800",
    "relative text-gray-800 bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-600",
  ];

  return (
    <div
      className={
        (levelBlockStyles[level] || levelBlockStyles.pop()) +
        `${locked ? " pointer-events-none opacity-50" : ""}`
      }
    >
      <div className="flex justify-between space-x-3">
        <div className="min-w-0 flex-1">
          {locked && (
            <span className="flex items-center">
              <span className="mr-2 opacity-25 text-sm">
                <IconElement icon="lock" />
              </span>
              <span className="absolute inset-0" aria-hidden="true" />
              <p className={levelTextStyles[level] || levelTextStyles.pop()}>
                {title}
              </p>
            </span>
          )}

          {!locked && (
            <a
              href={url}
              className="flex items-center focus:outline-none"
              data-tracking-action-label="NAVIGATION"
              data-tracking-action-value={`${title} | ${url}`}
              data-tracking-action-position="Course Module Full ToC"
            >
              <span className="mr-2 opacity-25 text-sm">
                <IconElement icon="arrowRight" />
              </span>
              <span className="absolute inset-0" aria-hidden="true" />
              <p className={levelTextStyles[level] || levelTextStyles.pop()}>
                {title}
              </p>
            </a>
          )}
        </div>
        {duration && (
          <time
            dateTime={duration + "min"}
            className="flex-shrink-0 whitespace-nowrap text-sm"
          >
            {Math.floor(duration / 60)}h{" "}
            {duration % 60 > 0 && (
              <Fragment>{Math.floor(duration % 60)} min</Fragment>
            )}
          </time>
        )}
      </div>
      {description && (
        <div className="mt-1">
          <p className="line-clamp-2 text-sm text-gray-600">{description}</p>
        </div>
      )}
    </div>
  );
}

function NestedCourseTocRenderer({
  items,
  level,
  slug,
  locked = true,
  embedded = false,
}) {
  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <ol className={!embedded ? "divide-y divide-gray-200" : "space-y-6"}>
      {items.map(({ url, title, items: subItems }) => (
        <Fragment key={url + title}>
          <li>
            <CourseModuleTocItem
              key={slug + url}
              url={slug + url}
              title={title}
              level={level}
              locked={locked}
              embedded={embedded}
            />
          </li>
          {Array.isArray(subItems) && (
            <li className="ml-4">
              <NestedCourseTocRenderer
                items={subItems}
                level={level + 1}
                slug={slug}
                locked={locked}
                embedded={embedded}
              />
            </li>
          )}
        </Fragment>
      ))}
    </ol>
  );
}

export function CourseModuleToc({ node, embedded = false }) {
  const {
    tableOfContents: { items },
    fields: { slug },
    frontmatter: { title, headline, duration, public: isPublic = false },
  } = node;
  return (
    <nav className={embedded ? "flex p-4 sm:py-8 sm:px-4 bg-gray-50" : ""}>
      {!embedded && (
        <CourseModuleTocItem
          url={slug}
          title={headline || title}
          duration={duration}
          level={0}
          locked={!isPublic}
          embedded={embedded}
        />
      )}

      {Array.isArray(items) && (
        <NestedCourseTocRenderer
          slug={slug}
          items={items}
          level={1}
          locked={!isPublic}
          embedded={embedded}
        />
      )}
    </nav>
  );
}
